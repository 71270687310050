// Import all avatars dynamically
const avatarContext = require.context(
  "../../../../../../assets/icons/Arts -avatars",
  false,
  /\.png$/
);

const avatars = avatarContext.keys().reduce((acc, path) => {
  const id = path.match(/\d+/)?.[0]; // Extract number from filename
  if (id) acc[id] = avatarContext(path);
  return acc;
}, {});

// Function to get avatar by ID
const getAvatar = (avatar_id) => avatars[avatar_id] || null;

export default getAvatar;