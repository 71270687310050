import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useNavigate } from "react-router-dom";


const TryFree = ({ CloseResp, showDialog }) => {
    const [resp, setResp] = useState("false");
    const organization_id = localStorage.getItem('organization')
    const email = localStorage.getItem('email');
    const navigate = useNavigate();
    const UpdateLicense = async () => {
        setResp('progress');
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/organization/${organization_id}/updateLicense?transactionRef=Free&email=${email}&amount=0&license_type=3&bill_type=monthly`);
            setResp('updated');
        } catch (error) {
            //console.log('error in updating', error)
            setResp('error');
        }
    };
    const closeDialog = () => {
        if (resp === 'updated') {
            localStorage.setItem('plan', 3);
            setResp(false);
            CloseResp();
            window.location.reload();

        } else if (resp === 'EmailNotActive'|| resp === 'NoTrial') {
            setResp(false); 
            CloseResp();
            navigate('/Dashboard/settings');
            
        } else { setResp(false); CloseResp();}
    }
    // create date of today one month plus
    const addOneMonth = () => {
        const currentDate = new Date();
        const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        // Subtract one day from the new date
        nextMonth.setDate(currentDate.getDate() - 1);
        // Format the date as DD/MM/YYYY
        const day = String(nextMonth.getDate()).padStart(2, '0');
        const month = String(nextMonth.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = nextMonth.getFullYear();

        return `${day}/${month}/${year}`;
    }
    const HandleValidation = () =>{
        let error = false;
        const emailActive = localStorage.getItem('emailActive');
        const activatedTrial = localStorage.getItem('activatedTrial');
        if (!emailActive) {
            error = true;
            setResp('EmailNotActive')
        }else if (activatedTrial) {
            error = true;
            setResp('NoTrial')
        }
        if (!error) {
            UpdateLicense();
        }
    }
    
    useEffect(() =>{
        HandleValidation();
    },[]);

    return (
        showDialog && (
            <Dialog open={resp} onClose={closeDialog}>
                <DialogContent className="flex flex-col items-center justify-center gap-4">
                    {resp === 'progress' ? (
                        <div className="flex flex-col items-center justify-center w-full text-center">
                            <DialogTitle>Upgrading your license... please wait</DialogTitle>
                            <CircularProgress className="text-blue-800 mt-2" />
                        </div>
                    ) : (
                        <Alert severity={resp === 'updated' ? 'success' : 'error'} className="w-full">
                            {resp === 'updated'
                                ? `Congratulations 🎉, You now have the premium plan for a month. Your free trial ends by ⌚ ${addOneMonth()}.`
                                : resp === 'EmailNotActive'
                                ? 'Activate your email to use the free one-month premium plan.'
                                : ''}
                        </Alert>
                    )}
                </DialogContent>
    
                {resp !== 'progress' && (
                    <div className="flex justify-center pb-3">
                        {resp === 'updated' && <Button onClick={closeDialog}>Game ON</Button>}
                        {resp === 'EmailNotActive' && <Button onClick={closeDialog}>Activate my email now</Button>}
                    </div>
                )}
            </Dialog>
        )
    );
    
};
export default TryFree;