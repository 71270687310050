import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import getAvtar from "./pages/sidebar/sidbarOptionsContent/The Gamizign World/Components/GetAvtar";
import GMZbg from '../assets/images/SigninPageImages/BG_for_Login_12-10-2024.png';
import timing from "../assets/icons/SVGIcons/Timing.svg";
import spin from "../assets/icons/SVGIcons/Spin.svg";
import arcade from "../assets/icons/SVGIcons/Arcade.svg";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const Players = () => {
  const [players, setPlayers] = useState({});
  const ws = useRef(null); // Store WebSocket instance
  const [isConnected, setIsConnected] = useState(false);
  const { activity } = useLocation().state;
  const AuthToken = `${sessionStorage.getItem('token')}`;
  const [questionList, setQuestionList] = useState([]);

  const getGameIcon = (gameIcon) => {
    switch (gameIcon) {
      case "timing":
        return timing;
      case "spin":
        return spin;
      case "arcade":
        return arcade;
      default:
        return null;
    }
  };

  // WebSocket connection setup
  const connectWebSocket = () => {
    ws.current = new WebSocket(`wss://gamizign.co:8000/ws/session/${activity.activity_id}`);

    ws.current.onopen = () => {
      setIsConnected(true);
      var inst = {
        "action": "join",
        "player": "Instructor",
        "instructor_id": parseInt(localStorage.getItem("instructor_id")),
        "score": "0"
      };
      ws.current.send(JSON.stringify(inst));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "update") {
        const updatedPlayers = message.players;
        setPlayers(updatedPlayers);
      }
    };

    ws.current.onclose = () => {
      setIsConnected(false);
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  };

  useEffect(() => {
    connectWebSocket();

    const activity_id = activity.activity_id;
    const getQuestions = async (activity_id) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/activities/${activity_id}/`, {
          headers: {
            'Authorization': AuthToken
          }
        });
        const questions = await axios.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/${response.data.questionList_id}`, {
          headers: {
            'Authorization': AuthToken
          }
        });
        setQuestionList(questions.data.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    getQuestions(activity_id);

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [activity.activity_id, AuthToken]);

  const sortedPlayers = Object.entries(players || {})
    .map(([name, data]) => ({ name, ...data }))
    .sort((a, b) => b.score - a.score);

  return (
    <div className="grow">
      <div className="grow my-5 px-3">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${GMZbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            opacity: 0.3,
            zIndex: -1,
          }}
        ></div>
        <h1 className="text-xl font-bold">Live Leaderboard</h1>

        <div className="mt-4">
          <div className="mt-4 grid gap-4">
            <div className="mt-4 p-4 text-white bg-rose-600 rounded-xl shadow-md flex justify-around font-bold">
              <span>Rank</span>
              <span>Name</span>
              <span>Game</span>
              <span>Correct / {questionList.length}</span>
              <span>Score</span>
            </div>
            <AnimatePresence>
              {sortedPlayers.map((player, index) => (
                <motion.div
                  key={index}
                  layout
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                >
                  <div className="grid grid-cols-5 items-center p-4 text-violet-900 bg-white rounded-xl shadow-md hover:bg-violet-900 group cursor-pointer">
                    <div className="text-xl font-bold text-violet-900 group-hover:text-white text-center">
                      {index + 1}
                    </div>

                    <div className="flex items-center gap-4 m-auto">
                      <img
                        src={getAvtar(parseInt(player.avatar_id))}
                        alt="avatar"
                        className="h-16 w-16 rounded-full"
                      />
                      <span className="text-lg font-semibold text-violet-900 group-hover:text-white">
                        {player.player}
                      </span>
                    </div>

                    <div className="flex justify-center">
                      <img
                        src={getGameIcon(player.game)}
                        alt="game"
                        className="h-16 w-16 rounded-full"
                      />
                    </div>

                    <div className="text-lg font-semibold text-violet-900 group-hover:text-white text-center">
                      {player.correct}
                    </div>

                    <div className="text-lg font-semibold text-violet-900 group-hover:text-white text-center">
                      {player.score}
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Players;